// import logo from "./logo.svg";
// import "./App.css";
// import Home from "./routes/SpeechRecognition";
// import { fetchToken, onMessageListener } from "./firebase";
// import { useEffect, useState } from "react";
// import firebase from "firebase/compat/app";
// import messaging from "firebase/compat/messaging";
// import axios from "axios";
// import "react-toastify/dist/ReactToastify.css";
// import { toast } from "react-toastify";
import axios from "axios";
import SpeechRecognition from "./routes/SpeechRecognition";
// import Speech from 'react-speech';
// const client = new WebSocket('ws://localhost:3002');
// import { useSpeechSynthesis } from 'react-speech-kit';
import React, { useEffect, useState } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import { useParams } from "react-router-dom";
// client.addEventListener('open', function () {
//   console.log("open nè");
// });

// client.addEventListener('message', function (data) {
//   console.log("có me nè nè", data);
// });

function CourseDetail() {
  const [value, setValue] = useState("");
  const [contentActive, setContentActive] = useState("1");
  const [keyboicanh, setKeyboicanh] = useState("1");
  const [boicanh, setBoicanh] = useState({});
  const [courseId, setCourseId] = useState(null);
  const [title, setTitle] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [debai, setDebai] = useState("");
  const [showConversation, setShowConversation] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    // Lấy query string từ URL

    console.log("====================================");
    console.log(id, "dasdakjlsa");
    console.log("====================================");
    setCourseId(id);

    const fetchData = async () => {
      const response = await axios.get(
        "https://ewise.edu.vn/wp-json/wp/v2/conversation_course/" + id
      );
      console.log("====================================");
      console.log(
        response,
        "https://ewise.edu.vn/wp-json/wp/v2/conversation_course/2040"
      );
      console.log("====================================");

      const { excerpt, title, content } = response.data;

      setTitle(title.rendered);
      setExcerpt(excerpt.rendered?.replace(/<\/?[^>]+(>|$)/g, ""));
      setDebai(content.rendered);
    };

    fetchData();
  }, []);

  const data_content = [
    {
      key: "1",
      value:
        "Bối cảnh bạn và tôi là hai người bạn, tình cờ gặp nhau ở đường, bạn đang trên đường đi cắm trại",
    },
    {
      key: "2",
      value: "Tôi và bạn đang đi câu cá thì tình cờ gặp nhau",
    },
    {
      key: "3",
      value: "Tôi và bạn lần đầu tiền gặp nhau, địa điểm ở một ngôi chùa",
    },
  ];

  const [dataConversation, setDataConversation] = useState([]);
  const { speak, voices } = useSpeechSynthesis();
  const [voiceIndex, setVoiceIndex] = useState(2); // Chọn giọng mặc định

  console.log(voices, "voidsahfjkdsafjk");

  const genMessage = async (prompt) => {
    const newConversation = [
      ...dataConversation,
      { role: "user", content: prompt },
    ];

    console.log(newConversation, "newConversation ");
    console.log(excerpt, "boicanh ");

    let data = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content:
            "bạn là người nước mỹ, hãy nói chuyện bằng tiếng anh, giả lập nói chuyện để tôi học tiếng anh",
        },
        {
          role: "system",
          content: excerpt,
        },
        ...newConversation,
      ],
    });

    console.log(data, "data dá");

    let config = {
      method: "post",
      url: "https://api.openai.com/v1/chat/completions",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 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",
      },
      data: data,
    };

    const response_chat_gpt = await axios.request(config);

    console.log(response_chat_gpt.data, "dàhdsajk");

    const choices = response_chat_gpt.data.choices;
    const choice = choices[0];
    const text = choice.message.content;

    return text;
  };

  useEffect(() => {
    console.log(dataConversation, "new dataConversation");
  }, [dataConversation]);

  useEffect(() => {
    console.log(dataConversation, "new dataConversation");

    const data_boicanh = {
      role: "system",
      content: data_content.find((i) => i.key == contentActive).value,
    };

    setBoicanh({ ...data_boicanh });

    setDataConversation([]);
  }, [contentActive]);

  const onclickGentextLog = async () => {
    const text = await genMessage(value);
    console.log(text, "text gen");
  };

  const onclickTalk = async () => {
    const text = await genMessage(value);

    setDataConversation(
      [...dataConversation].concat([
        { role: "user", content: value },
        {
          role: "assistant",
          content: text,
        },
      ])
    );

    console.log(text, "text gen");
    speak({ text: text, voice: voices[voiceIndex] });
  };

  const genTitleRole = (role) => {
    if (role === "user") {
      return "Học viên";
    } else {
      return "Ewise";
    }
  };

  const onClickContent = (key) => {
    setContentActive(key);
  };

  const renderListContent = data_content.map((i) => {
    return (
      <div className="col-4 p-2">
        <div
          onClick={() => onClickContent(i.key)}
          className={`card alert ${
            i.key == contentActive ? "alert-success" : "alert-primary"
          }`}
        >
          {i.value}
        </div>
      </div>
    );
  });

  return (
    <div className="container">
      <select
        value={voiceIndex}
        onChange={(e) => setVoiceIndex(e.target.value)}
      >
        {voices
          .filter((i) => i.lang == "en-US" || i.lang == "en-GB")
          .map((voice, index) => (
            <option key={index} value={index}>
              {voice.name} ({voice.lang})
            </option>
          ))}
      </select>
      <h4>{title}</h4>
      <div className="">
        <div className={`alert-success p-4 mb-2`}>
          Đề bài :
          <div dangerouslySetInnerHTML={{ __html: debai }} />
        </div>
      </div>
      {/* <div className="row">{renderListContent}</div> */}
      <div className="row">
        <div className="col-6">
          <div
            style={{
              position: "sticky",
              top: 20,
            }}
          >
            <SpeechRecognition setValue={setValue} />
            <div>
              <textarea
                value={value}
                onChange={(event) => setValue(event.target.value)}
              />
            </div>
            {/* <button onClick={() => speak({ text: value })}>Speak</button>
      <button onClick={() => onclickGentextLog()}>gen text chat gpt</button> */}
            <button className="btn-primary btn" onClick={() => onclickTalk()}>
              Gửi đi
            </button>
          </div>
        </div>
        <div className="col-6">
          <button
            className="btn-success btn mb-4"
            onClick={() => setShowConversation(!showConversation)}
          >
            {showConversation ? "Ẩn hội thoại" : "Hiện hội thoại"}
          </button>
          {showConversation && (
            <div
              style={{
                minHeight: 600,
              }}
            >
              {dataConversation.map((i) => {
                return (
                  <div
                    style={{
                      textAlign: i.role == "user" ? "left" : "right",
                    }}
                  >
                    <p className="bg-light p-3 d-inline-block card">
                      <span className="block bg-success p-1 mb-2">
                        {genTitleRole(i.role)}
                      </span>
                      <br></br>
                      {i.content}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CourseDetail;
