import React, { useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Dictaphone = ({ setValue }) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    setValue(transcript)
  }, [transcript])


  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div>
      <p>Microphone: {listening ? 'on' : 'off'}</p>
      <button className="btn btn-primary" onClick={SpeechRecognition.startListening}>Bắt đầu nói</button>
      <button className="btn btn-warning m-2" onClick={SpeechRecognition.stopListening}>Dừng nói</button>
      {/* <button className="btn btn-secondary" onClick={resetTranscript}>Reset</button> */}
      <p>{transcript}</p>
    </div>
  );
};
export default Dictaphone;